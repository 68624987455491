.main-d{
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-d{
    background-color: black;
    position: relative;

    width: 50%;
    height: 100vh;
    margin: 0%;
    padding: 0;
}
.img-d{
    position: absolute;
    top:50%;
    left:50%;
    right: 50%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%,-50%);
    width: 13rem;
    height: 13rem;
}
.right-d{
    width:50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.customer-d,.supplier-d{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 70%;
    height: 12rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 10px;

}
.customer-d{
    background-color: #fca702;

}

.supplier-d{
    background-color: #0275fc;
}
.btn{
    background-color: black !important;
    color: white!important;
    margin-top: 1rem !important;
}
.title{
    font-weight: 800;
    color: black;
}
@media screen and (max-width:768px) {
    .left-d{
        display: none;
    }
    .right-d{
        width: 100%;
    }
}