.prodDescription{
    white-space: pre-wrap;
}
.viewProdHead{
    font-size: 1.786em;
    line-height: 1.28em;
    margin-bottom: .48em;
}
@media screen and (min-width:700px) {
    div.description_area{
        padding-left: 80px;
    }  
}
div.description_area{
    
    margin-top: 40px;
    margin-bottom: 40px;
}
.viewProdAvailable{
    font-size: 16px;
    margin-bottom: 15px;
    color: #768b9e;
}
.skuName{
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
}
.prodDescription{
    padding-left: 20px;
    padding-top: 15px;
}
.curPrice{
    font-size: 30px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 14px;
}
.addToCartBtn{
    margin-left: 30px;
    cursor: pointer;
    height: 53px;
    border-radius: 13px;
    outline: none;
    border: 0px;
    font-size: 1.3em;
    padding: 10px;
    width: 200px;
    background-color: #2c69d1;
    color: white;
    font-weight: 600;
    
}
.addToCartBtn:hover{
    background-color: #2c69d1d7;
}
.skze{
    font-size: 14px;
    margin-top: 10px;
}
.skze1{
    margin-top: 22px;
}
.header_feature{
    font-size: 25px !important;
    font-weight: 700 !important;
}
#specs{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
#specs td, #specs th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  #specs tr:hover {background-color: #ddd;}