.pcb-cart {
    padding-bottom: 50px;
} 
.cart_head {
    text-align: center;
}
.cart_table_content {
    border: 1px solid rgb(168, 162, 162);
}
.cart_table {
    width: 100%;
    text-align: center;
    border-spacing: 0px;
    
}
.cart_table thead {
    border-bottom: 1.2px solid rgb(214, 206, 206);
}
.cart_table tr, td, th {
    padding: 10px 0px;
    border-bottom: 1px solid rgb(139, 133, 133);
}
td img {
    max-height: 50px;
}
.cart_pd {
    display: flex;
    align-items: center;
    justify-content: start;
}
.cart_item_delete {

}

button:focus {
    outline: none;
}

.cart_bottom {
    margin: 0px  30px 20px 0px;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.cart_total {
    font-weight: 800;
    text-align: right;
    padding-top: 8px;
    width: 100%;
    padding-right: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 15px;
}
.coupon input {
    border: none;
    border: 1px solid gray;
    padding: 8px 10px;
    border-radius: 20px 0px 0px 20px;
}
.proceed_cart{
  margin-bottom:10px;
}
.proceed_cart button {
    padding: 9px 25px;
    margin-left: 13px;
    border: none;
    background-color: #0ABCF9;
    color: white;
    border-radius: 7px;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
}

.proceed_cart button:hover {
    background-color: #0a91f9;
    transition: all .6s ease-in-out;
}


.add_new_ship {
    margin-top: 30px;
}