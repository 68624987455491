.heading {
    font-family: Roboto;
    font-weight: 400;
    font-size: 32px;
    line-height: 16px;
}
.content_header {
    padding: 50px;
    height: fit-content;
    width: 100%
}
.main_outer_container{
    width: 100vw;
    height:100vh;
    display:flex;
    flex-direction: column;
}
.inner_container{
    flex:1;
    overflow-y: auto;
    padding:10px 50px
}