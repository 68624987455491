.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content{
    display:flex;
    align-items: center;
}
#billBreakDown{
    border-collapse: collapse;
}

#billBreakDown tr>td:nth-child(2){
    padding-left: 25px;
}
#billBreakDown td{

    border-color: #00000021;
}