.shipping_to{
    margin-top: 30px;
}
.shipping_to p {
 margin: 0px;
}
.order_status_modal {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(200, 185, 212);
}
.order_status_modal img {
    max-width: 120px;
}
.s_product {
    padding: 10px !important;
    border: 1px solid rgb(192, 155, 226);
    margin-bottom: 5px;
}
.order_status {
    font-size: 14px;
    font-weight: normal;
    color: rgb(230, 34, 34)
}
.p_details {
    margin-left: 20px;
    font-size: 10px;
}
.order_ship_time {
    font-size: 14px;
    margin-top: -10px;
}