.signUpheader {
  /* margin-top: 30px;
  margin-left: 30px; */
}

.content_wrap {
  display: flex;
  justify-content: space-between;

}

.leftt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  height: 95vh;
}

.leftt_content_login_supplier22 {
  /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */
  /* align-content: flex-start; */
  height: 120vh;
  margin-top: -80px;
  background-color: black;
  width: 50%;
  flex:1;
  position: relative;
  /* margin-left: -2rem; */
  display: grid;
  place-items: center;
}

.rightt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: -80px;
  height: 95vh;
}

.main_content {
  width: 50%;
  margin: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  margin-right: 3rem;
}

/* Track */
.main_content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.034);
}

/* Handle */
.main_content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.main_content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.signUp_head {
  font-size: 1.5em;
  font-weight: 600;
}

.secondary_head {
  padding-top: 15px;
  padding-bottom: 15px;
  color: rgba(0, 0, 0, 0.747);
}

.sign_up {
  font-size: 1.1em;
}


.signUpheaderSupplier {

  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}

.signUpheaderSupplier img {
  width: 13rem;
  height: 6rem;
}

.content_wrap_supplier {
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (min-width:997px) {

  /* width */
  .main_content::-webkit-scrollbar {
    width: 0px;
  }

  .signUpWrapper {

    overflow-y: hidden;
    height: 100vh;
    overflow-y: hidden;
  }

  .main_content_signup {
    width: 40%;
    height: 100vh;
    overflow-y: auto;
  }
}

@media screen and (max-width:997px) {

  .leftt_content,
  .leftt_content_supplier22 {
    display: none;
  }

  .rightt_content {
    display: none;
  }

  .main_content {
    width: 100%;

    overflow-y: hidden;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
  }

}

.l_body {
  margin-top: 3vh;
}





/*---------------------*/

.signUpheaderLogin {
  /* display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-direction: column;
    margin-left: -100px; */
  position: absolute;
  /* top:50%;
    left:50%;
    right: 50%;
    bottom: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%,-50%); */
  text-align: center;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.signUpheaderLogin img {
  width: 13rem;
  height: 6rem;
}

.signUpheaderLogin p {
  width: 20rem;
  color: white;
  margin-top: 1rem;
  font-size: 1.2rem;

}

.signUpheader {
  margin-left: 30px;
  height: 5vh;
  margin-top: 30px;
}

.content_wrap {
  display: flex;
  justify-content: space-between;

}

.leftt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  height: 95vh;
}

.rightt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: -80px;
  height: 95vh;
}



/* Track */
.main_content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.034);
}

/* Handle */
.main_content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.main_content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.signUp_head {
  font-size: 1.5em;
  font-weight: 600;
}

.secondary_head {
  padding-top: 15px;
  padding-bottom: 15px;
  color: rgba(0, 0, 0, 0.747);
}

.sign_up {
  font-size: 1.1em;
}

@media screen and (min-width:997px) {

  /* width */
  .main_content::-webkit-scrollbar {
    width: 0px;
  }

  .signUpWrapper {

    overflow-y: hidden;
    height: 100vh;
    overflow-y: hidden;
  }

  .main_content {
    width: 45%;
    height: 100vh;
    overflow-y: auto;
  }
}

@media screen and (max-width:997px) {
  .leftt_content {
    display: none;
  }

  .rightt_content {
    display: none;
  }

  .main_content {
    width: 100%;

    overflow-y: hidden;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
  }

}

.l_body {
  margin-top: 3vh;
}