.prod_card{
    cursor: pointer;
    width: 100%;
    height: 410px;
    transition: all ease-in-out 0.1s;
    padding-bottom: 30px;
    margin-bottom: 20px;
}
.prod_card:hover{
    /* box-shadow: 3px 3px 1px 1px #2c69d1; */
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%);
}
.prod_card_img{
    height: 70%;
    margin-bottom: 10px;
}
.prod_card_img img{
    height: 100%;
    width: 100%;
    object-fit: fill;
}
.prod_details{
    height: 30%;
    padding: 10px;
}
.prod_name{
    color: #878787;
    font-size: 21px;
    word-wrap: break-word;
}
.prod_price{
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.815);
}
.prod_sku{
    font-size: 19px;
    color:black;
}
.main_prod_wrap{
    background-color: white;
    min-height: 86vh;
    border-radius: 4px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-top: 30px;
}