.l_body form{
    display: flex;
    flex-direction: column;
    /* max-width: 8px; */
    width: 100%;
    max-width: 700px;
    justify-content: center;
}

.main_new_login{
    display: flex;
    justify-content: "center";
    align-items: "center";
}

.main_new_login .left_black{ 
    background-color: black;
    width: 50%;
}
.loginHeader{
  height: 5vh;
}

.l_body{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 60px;
}
.l_body h3{
    font-size: 2em;
    margin-bottom: 60px;
}
.loginWrapper{
    display: flex;
    overflow-x: hidden;
}
.left_content{
    width: 65%;
    height: 100vh;
    overflow-y: auto;
}
.l_header{
    padding: 20px;
}
.right_content{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 35%;
    height: 100vh;
    background-color: #2E2E2E;
    overflow:hidden;
}
@media screen and (max-width:1144px) {
    .left_content{
        width: 105%;
        height: 100vh;
        overflow-y: auto;
        padding-right: 30px;
        padding-left: 30px;
    }
    .right_content{
        display: none;
    }
}
.or_opt{
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid rgba(0, 0, 0, 0.342); 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    padding-top: 19px;
}
.or_opt span { 
    background:#fff; 
    padding:0 10px; 
}
.sign_up{
    padding-top: 30px;
    font-size: 20px;
}
.signUp_head_login{
    font-size: 1.9em;
    margin-bottom: 40px;
    margin-top: 30px;
    font-weight: 600;
}
.leftt_content_login{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 95vh;
    margin-top: -80px;
}
.rightt_content_login{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;  
    height: 95vh;
}
.leftt_content_login_supplier1{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 120vh;
    margin-top: -80px;
    background-color: black;
    width: 50%;
    position: relative;
    margin-left: -2rem;
}





.signUpheaderLogin{
    position: absolute;
    top:50%;
    left:50%;
    right: 50%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%,-50%);
    
}
.signUpheaderLogin img{
    width: 13rem;
    height: 13rem;
}

.content_wrap_supplier{
    display: flex;
    justify-content: center;
    align-items: center;
}
.main_content_login{
    width:50%;
    /* margin: 1rem; */
    padding: 1rem; 
}
@media screen and (max-width:997px)  {
    .leftt_content_login, .leftt_content_login_supplier1{
        display: none;
    }
    .rightt_content_login{
        display: none;
    }
    .main_content_login{
        width:100%;
        /* margin: 1rem; */
        padding: 1rem; 
    }
  }