.shipping_address {
    
}
.shipping_address h6 {
    margin: 0;
    padding: 0;
    color: rgba(41, 36, 36, 0.981);
}
.shipping_address p {
    margin: 0;
    padding: 0;
}
.product_d,
.user_d,
.shipping_add {
    margin: 10px 0px;
}

.selectStatus {
    display: flex;
    flex-direction: column;
}

.shipping_address h5 {
    color: black;
}

.active_status {
 background-color: rgb(136, 136, 221);
 color: white;
 border-radius: 4px;
}

.status {
    background: #fff;
    color: rgba(49, 42, 42, 0.884);
}
.status_btn {
    width: 200px;
    margin-top: -5px;
    border: none;
    border: 1px solid blueviolet;
    padding: 5px 20px; 
    background: blueviolet;
    color: white;
    border-radius: 3px;
}
.status_list {
    width: 200px;
    list-style-type: none;
    padding: 0px;
}
.status_list li {
    padding: 10px;
    text-align: center;
}
.status_message {
    margin-top: 10px;
}
.selectStatus h6 {
    color: rgba(41, 36, 36, 0.981);
}