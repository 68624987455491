.leftProfile_wrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /* padding-bottom: 30px; */


}

@media screen and (max-width:800px) {
    .leftProfile_wrapper {
        height: 310px;
    }
}

.leftProfile_wrapper>h3 {
    font-size: 2rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.815);
}

.leftProfile_wrapper>div {
    width: 200px;
    height: 200px;
    margin-bottom: 21px;
}

.prof_edit_form {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    margin-bottom: 20px;
}

.button_opts {
    margin-top: 20px;
}

.prof_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 80vh;
}



.profile_header {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: 700;
    margin-top: 50px;
}

.profile_label {
    color: #898888;
    font-size: 18px;
    font-weight: 500;
}

.account_signup{
    margin: 20px 0;
}

.signup_option{
    font-weight: 300;
    font-size: 18px;
    margin: 20px 20px 0px 0px;
}

.active_option{
    color: #0275FC;
    font-weight: 700;
    font-size: 18px;
}

.team_section{
    margin: 20px 0 40px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.small_text{
    font-size: 12px;
    color: #7A7A7A;
}
.address_section{
    margin: 20px 0;
}

.image_modal_container{
    position: absolute;
    width: 30vw;
    height: 30vw;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: white;
    overflow: hidden;
}