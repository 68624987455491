.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
}
.textfields input{
    
    border:none
 }
 .textfields input:focus{
     outline: none;
 
 }
 
.top {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.details {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 1.8rem;
}

.text {
    width: 100%;
    margin-bottom: 2rem !important;
}

.sub-title {
    color: gray;
    margin-bottom: 4rem;
    margin-top: -0.5rem;
}

.button {
    background-color: #0275fc !important;
    width: 50%;
    color: white !important;
}

@media screen and (max-width:768px) {
    .text {
        width: 80%;

    }
}

.supplier_form{
    text-align: center;
}