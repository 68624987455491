@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html{
  max-width: 100vw ;
  overflow-x: hidden;
}


.App {
  text-align: center;
  font-family: 'Poppins';
}

a{
  text-decoration: none !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.l_body form {
    display: flex;
    flex-direction: column;
    /* max-width: 8px; */
    width: 100%;
    max-width: 700px;
    justify-content: center;
}

.main_new_login {
    display: flex;
    justify-content: "center";
    align-items: "center";
}

.main_new_login .left_black {
    background-color: black;
    width: 50%;
}

.loginHeader {
    height: 5vh;
}

.l_body {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 60px;
}

.l_body h3 {
    font-size: 2em;
    margin-bottom: 60px;
}

.loginWrapper {
    display: flex;
    overflow-x: hidden;
}

.left_content {
    width: 65%;
    height: 100vh;
    overflow-y: auto;
}

.l_header {
    padding: 20px;
}

.right_content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 35%;
    height: 100vh;
    background-color: #2E2E2E;
    overflow: hidden;
}

@media screen and (max-width:1144px) {
    .left_content {
        width: 105%;
        height: 100vh;
        overflow-y: auto;
        padding-right: 30px;
        padding-left: 30px;
    }

    .right_content {
        display: none;
    }
}

.or_opt {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.342);
    line-height: 0.1em;
    margin: 10px 0 20px;
    padding-top: 19px;
}

.or_opt span {
    background: #fff;
    padding: 0 10px;
}

.sign_up {
    padding-top: 30px;
    font-size: 20px;
}

.signUp_head_login {
    font-size: 1.9em;
    margin-bottom: 40px;
    margin-top: 30px;
    font-weight: 600;
}

.leftt_content_login {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 95vh;
    margin-top: -80px;
}

.rightt_content_login {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    height: 95vh;
}

.leftt_content_login_supplier12 {
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */
    /* align-content: flex-start; */
    height: 120vh;
    margin-top: -80px;
    background-color: black;
    width: 50%;
    position: relative;
    /* margin-left: -2rem; */
    display: grid;
    place-items: center;
}





.signUpheaderLogina {
    position: absolute;
    /* top:50%;
    left:50%;
    right: 50%;
    bottom: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: translate(-50%,-50%); */
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
}

.signUpheaderLogina img {
    width: 13rem;
    /* height: 6rem; */
}

.signUpheaderLogina p {
    width: 20rem;
    color: white;
    margin-top: 1rem;
    font-size: 1.2rem;

}

.content_wrap_supplier {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_content_login {
    width: 50%;
    /* margin: 1rem; */
    padding: 1rem;
}

@media screen and (max-width:997px) {

    .leftt_content_login,
    .leftt_content_login_supplier12 {
        display: none;
    }

    .rightt_content_login {
        display: none;
    }

    .main_content_login {
        width: 100%;
        /* margin: 1rem; */
        padding: 1rem;
    }
}
.signUpheader {
  /* margin-top: 30px;
  margin-left: 30px; */
}

.content_wrap {
  display: flex;
  justify-content: space-between;

}

.leftt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  height: 95vh;
}

.leftt_content_login_supplier22 {
  /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */
  /* align-content: flex-start; */
  height: 120vh;
  margin-top: -80px;
  background-color: black;
  width: 50%;
  flex:1 1;
  position: relative;
  /* margin-left: -2rem; */
  display: grid;
  place-items: center;
}

.rightt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: -80px;
  height: 95vh;
}

.main_content {
  width: 50%;
  margin: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  margin-right: 3rem;
}

/* Track */
.main_content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.034);
}

/* Handle */
.main_content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.main_content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.signUp_head {
  font-size: 1.5em;
  font-weight: 600;
}

.secondary_head {
  padding-top: 15px;
  padding-bottom: 15px;
  color: rgba(0, 0, 0, 0.747);
}

.sign_up {
  font-size: 1.1em;
}


.signUpheaderSupplier {

  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.signUpheaderSupplier img {
  width: 13rem;
  height: 6rem;
}

.content_wrap_supplier {
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (min-width:997px) {

  /* width */
  .main_content::-webkit-scrollbar {
    width: 0px;
  }

  .signUpWrapper {

    overflow-y: hidden;
    height: 100vh;
    overflow-y: hidden;
  }

  .main_content_signup {
    width: 40%;
    height: 100vh;
    overflow-y: auto;
  }
}

@media screen and (max-width:997px) {

  .leftt_content,
  .leftt_content_supplier22 {
    display: none;
  }

  .rightt_content {
    display: none;
  }

  .main_content {
    width: 100%;

    overflow-y: hidden;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
  }

}

.l_body {
  margin-top: 3vh;
}





/*---------------------*/

.signUpheaderLogin {
  /* display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-direction: column;
    margin-left: -100px; */
  position: absolute;
  /* top:50%;
    left:50%;
    right: 50%;
    bottom: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%,-50%); */
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.signUpheaderLogin img {
  width: 13rem;
  height: 6rem;
}

.signUpheaderLogin p {
  width: 20rem;
  color: white;
  margin-top: 1rem;
  font-size: 1.2rem;

}

.signUpheader {
  margin-left: 30px;
  height: 5vh;
  margin-top: 30px;
}

.content_wrap {
  display: flex;
  justify-content: space-between;

}

.leftt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  height: 95vh;
}

.rightt_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: -80px;
  height: 95vh;
}



/* Track */
.main_content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.034);
}

/* Handle */
.main_content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.main_content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.signUp_head {
  font-size: 1.5em;
  font-weight: 600;
}

.secondary_head {
  padding-top: 15px;
  padding-bottom: 15px;
  color: rgba(0, 0, 0, 0.747);
}

.sign_up {
  font-size: 1.1em;
}

@media screen and (min-width:997px) {

  /* width */
  .main_content::-webkit-scrollbar {
    width: 0px;
  }

  .signUpWrapper {

    overflow-y: hidden;
    height: 100vh;
    overflow-y: hidden;
  }

  .main_content {
    width: 45%;
    height: 100vh;
    overflow-y: auto;
  }
}

@media screen and (max-width:997px) {
  .leftt_content {
    display: none;
  }

  .rightt_content {
    display: none;
  }

  .main_content {
    width: 100%;

    overflow-y: hidden;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
  }

}

.l_body {
  margin-top: 3vh;
}
.dashboard_wrapper1{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}
.dashboard_wrapper{
    display: flex;
    width: 100vw;
    flex-direction: row;
    background-color: #E9E9E9;
    height: 100vh;
}
.sider_menu{
    width: 250px;
    background-color: black;
    height: 100vh;
    color: white;
    overflow-y: auto;
    text-align: center;
  
    align-content: center;
}
.sider_menu img{
    margin-top: 20px;
}
.right_dashboard_content{
    /* width: calc(100% - 250px); */
    width: 100%;
}
.header_wrapper{
    display: flex;
    padding: 10px 50px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    /* padding-right: 20px; */
    background-color: white;  
}
.pop_over_item{
    cursor: pointer;
}
.pop_over_item:hover{
    background-color: #E9E9E9;
}
.stats_card{
    display: flex;
    min-height: 150px;
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    padding-left: 20px;
}
.stats_card>div:nth-child(2){
    padding-left: 20px;
}
.right_dashboard_content{
    height: 100vh;
    overflow-y: hidden;
}
.right_dashboard_content.admin{
    overflow-y: auto;
}
.paper_content_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.stats_wrapper{
    overflow-x: hidden;
  /* padding: 30px; */
}
.main_body{
    overflow-x: hidden;
}
.admin_main_body{
    /* background-color:#E9E9E9 ; */
    background-color: #fff;
    min-height: 100vh;
}
.dialog_remark{
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.089);
    margin-bottom: 10px;
}
.dialog_remark div:nth-child(2){
    color: rgba(0, 0, 0, 0.767);
 
    padding-top: 5px;
}
.dialog_remark div:nth-child(1){
    font-size: 18px;
    font-weight: 500;
}
.sec{
    padding-bottom: 10px;
    color: black;
}
.date_div{
    font-size: 1.1em;
}
.stat_card{
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.123);
    border-radius: 2px;
    padding: 5px;
    padding-left: 15px;
    margin-bottom: 15px;
}
.stat_card>div:nth-child(1){
    font-size: 59px;
}
.stat_card>div:nth-child(2){
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;   
}
.main_body{
    width: 100%;
}
.sec_head{
    font-size: 1em;
    font-weight: 600;
    color: #787878;
    display: flex;
   
    text-transform: uppercase;
}
.sec>p{
    flex-wrap: nowrap;
    white-space: pre-wrap;
}
.chips{
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}
.document_item{
    margin-bottom: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.212);
    padding: 5px;
    padding-left: 10px;
    border-radius: 4px;
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width:600px) {
    .costing_expand>div:nth-child(1){
        display: none;
    }
    .stats_card{
        font-size: 14px;
        width: 89vw;
        padding-bottom: 10px;
        justify-content: center;
    }
    .stats_card>div:nth-child(1){
        display: none;
    }
    .stats_card>div:nth-child(2){
        text-align: center;
        margin-left: 0px;
        padding-left: 0px;
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 1.1em;  
        padding-right: 10px; 
    }
    button.consult_book_large{
        display: none !important;
    }
    button.consult_book_small{
        display: inline-block !important;
    }
    .stats_row{
        margin-right: 10px !important;
    }
    .stat_table_row{
        margin-right: 10px !important;
    }
}
.right_dashboard_content{
    overflow-x: hidden;
}
button.consult_book_small{
    display: none ;
}
button.consult_book_large{
    display: inline-block ;
}
.text_timeline{
    text-align: left;
    margin-bottom: 10px;
}
.details_btn{
    display: flex;
    height: 40px;
    align-items: center;
    align-content: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.main_body{
    overflow-x: hidden;
}
.stats_card{
    overflow-x: auto;
}
.dialog_remark{
    display: flex;
    align-items: flex-start;
    align-content: center;
    overflow-x: hidden;
    min-width: 200px;
}
.remark_abt{
    margin-left: 20px;
    width: 100%;
}
.remark_creator{
  
    width: 100%;
}
.author_name{
    font-size: 15px;
    font-weight: 700;
    padding-right: 14px;
}
.cmt_date{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.527);
    font-weight: 500;
}
.avatar_area{
    padding-top: 7px;
}
.remark_info{
    font-size: 15px;
}
._wrap{
    overflow-y: hidden;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.foot_table{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.t_header{
    text-transform: uppercase;
    padding-left: 10px;
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.747);
}
.right_dashboard_content{
    background-color:#f4f4f4;
    /* background-color: #fff; */
}
.header_wrapper{
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.075);
}
.project_card{
    border-radius: 10px;
   
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: .625rem;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: white;
    transition: all ease-in-out 0.1s;
}
.project_card:hover{
    box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);
}
.project_card div{
    margin-bottom: 3px;
}
.project_name_card{
    font-size: 1.7em;
    font-weight: 600;
    padding-top: 15px;
    text-transform: uppercase;
}
.card_view_btn{
    margin-top: 20px;
    padding-bottom: 20px;
}
.time_wrap{
    min-height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}
.stat_wrapper{
    padding-top: 20px;
}
.card_view_btn a{
    text-decoration: none;
}
#fon_head{
    color: rgba(0, 0, 0, 0.753);
    font-size: 1.6em;
    padding-bottom: 20px;
}
.btn_opts_del{
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.documentHoler{
    height: 350px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.178);
    /* padding: 10px; */
    border-radius: 5px;
}
.documentItem{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    width: 90%;
    overflow-x: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: flex;
    border-right: 1px solid rgba(0, 0, 0, 0.116);
}
.document_Item{
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
}
#noDocAval{
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 15px;
}
.main_body{
    height: calc(100vh - 79px);
    background-color: #f4f4f4;
    /* background-color: #fff; */
}
.project_name_top{
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
}
.box_type{
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 14px;
}
.stats_card_right{
    font-size: 1.1rem;  
    color:#787878;
   
    width: 100%;
    padding-right: 20px;
}
.box__type{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 14px;
    width: 100%;
}
.box__type span:nth-child(1){
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
}
.detaails_btn{
    font-size: 17px;
    display: flex;
    font-weight: 600;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.685);
}
.detaails_btn:hover{
    color:#7B61FF;
}
.collapse_content{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
   
}
.collapse_content.visible{
    max-height: 400px;
   
}
.expand_btn.visible{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.expand_btn{
    margin-top: -1.4px;
    transition: all ease-in-out 0.1s;
}
.collapse_content{
    background: white;
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    border-radius: 10px;
    padding-left: 20px;
}
.costing_expand{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    align-content: center;
    align-items: center;
}
.hasscnd{
    margin-left: 42px;
    width: 100%;
}
.donKnow{
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}
.view_proj_wrapper{
    display: flex;
}
.right_proj_view{
    width: 50%;
}
.left_proj_view{
    width:50%;
}
.divider{
    background: rgba(0, 0, 0, 0.11);
    height: 1px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 93%;
}
.cost_el{
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    font-size: 1.1em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.678);
}
div.MuiTimelineOppositeContent-root{
    flex:0.2 1;
    
}
.form_el_cont{
    text-align: center;
    display: flex;
}
.proj_description_head{
    font-size: 23px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.932);
    margin-top: 42px;
    padding-bottom: 20px;
   
}
.proj_description{
    white-space: pre-wrap;
}
.bill_cmp{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
}
.bill_cmp.large{
    font-size: 22px;
}
.bill_cmp.large .bill_type{
    font-size: 22px;
}
.bill_cmp.large .bill_amount{
    font-size: 22px;
}
.bill_type{
    font-weight: 600;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.726);
}
.bill_amount{
    font-weight: 600;
    font-size: 17px;
    color: rgb(0, 0, 0);
}
.break_down_head{
    padding-left: 20px;
    margin-top: 18px;
    font-size: 17px;
    font-weight: 600;
    color: black;
    margin-bottom: 5px;
}
.collaborator_wrapper{
    display: flex;
    align-items: center;
    align-content: center;
}
.list_edit_item{
    display: flex;
    justify-content: space-between;
    padding-right: 7px;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.068);
    padding-bottom: 7px;
    padding-top: 5px;
}
.xcvr{
    margin-left: 7px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.815);
    font-size: 16px;
}
.collaborator_wrapper{
    display: flex;
}
.invite_text{
    font-size: 1.2em;
    padding-top: 20px;
    padding-bottom: 20px;
}
.remark_section_contain{
    max-height: 300px;
    overflow-y: auto;
}
.slector_cmts{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;   
}
.attach_icn{
    margin-right: 3px;
    padding-top: 13px;
    margin-left: -10px;
    display: flex;
}
div.downloadCmt{
    font-size: 1em !important;
    color: rgba(0, 0, 0, 0.747);
    font-weight: 600 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nothing_found{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 84vh;
}
.console_head{
    font-size: 1.8em;
    text-align: left;
    padding-left: 60px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.829);
}
.no_proj_text{
    font-size: 1.6em;
    color: rgba(0, 0, 0, 0.699);
}
.no_proj_text img{
    padding-left: 4px;
}
.form_label{
    margin-bottom: 5px;
    margin-top: 10px;
}
.billing_container{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
}
.billing_container h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    
}
.actions_btns{
    margin-top: 20px;
    margin-right: 10px;
}
.billing_divider{
    margin-top: 25px;
    border-top: 2px dotted #3f51b542;
}
.back_btn{
    position: absolute;
    top: 12px;
}
.bill_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
}
.txt_wrap{
    text-align: left;
    overflow-x: hidden;
    padding-top:20px;
    padding-bottom: 20px;
    text-align: center;
   
}
.project_card{
    overflow-x: hidden;
    text-align: center;
    height: 550px;
}
.proj_card_description{
    width: 100%;
    padding-left: 20px;    
    text-align: left;
}
.proj_card_description>div:nth-child(1){
    font-size: 1.1em;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.822);
}
.proj_card_description>div:nth-child(2){
    white-space: pre-wrap;
    text-align: left;
    margin-right: 24px;
}
.txt_wrap{
    width: 100%;
    overflow-y:auto ;
}
/* width */
.txt_wrap::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
.txt_wrap ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
.txt_wrap::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
.txt_wrap::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
.xysad{
    padding-bottom: 25px;
    margin-left: 5px;
    font-size: 1.5em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.884);
}
.leftProfile_wrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /* padding-bottom: 30px; */


}

@media screen and (max-width:800px) {
    .leftProfile_wrapper {
        height: 310px;
    }
}

.leftProfile_wrapper>h3 {
    font-size: 2rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.815);
}

.leftProfile_wrapper>div {
    width: 200px;
    height: 200px;
    margin-bottom: 21px;
}

.prof_edit_form {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    margin-bottom: 20px;
}

.button_opts {
    margin-top: 20px;
}

.prof_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 80vh;
}



.profile_header {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: 700;
    margin-top: 50px;
}

.profile_label {
    color: #898888;
    font-size: 18px;
    font-weight: 500;
}

.account_signup{
    margin: 20px 0;
}

.signup_option{
    font-weight: 300;
    font-size: 18px;
    margin: 20px 20px 0px 0px;
}

.active_option{
    color: #0275FC;
    font-weight: 700;
    font-size: 18px;
}

.team_section{
    margin: 20px 0 40px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.small_text{
    font-size: 12px;
    color: #7A7A7A;
}
.address_section{
    margin: 20px 0;
}

.image_modal_container{
    position: absolute;
    width: 30vw;
    height: 30vw;
    top: 50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    background-color: white;
    overflow: hidden;
}
.cconsole_head {
  height: 88px;
  left: 41px;
  top: 130px;
  margin-top: 24px;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.service_finder {
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px #00000033;
  height: 240px;
  max-width: 924px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
}

.find_serve_text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.find_serve_description {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.bookConsultation {
  height: 306px;
  width: 100%;
  left: 0px;
  top: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 4px;
}

.tanl_head {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.tal_des {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.795);
}

.allServices {
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px #00000033;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 924px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 80px;
  padding-right: 20px;
}

.serve_sec_head {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  margin-top: 40px !important;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.section_type {
  padding-left: 11px !important;
}

.service_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  /* color: #6200ee; */
  color: #0275FC;
  margin-top: 30px !important;
}

.service_text_text {
  padding-left: 10px;
  padding-top: 5px;
  color: #0275FC;
  text-transform: capitalize;
  font-size: 18px;
}

.service_description {
  padding-left: 10px;
  font-size: 15px;
  color: #838383;
  text-transform: capitalize;
  font-weight: 400;
  padding-left: 10px;
  padding-top: 2px;
  margin-top: 0.4rem;
}

.service_text_desc {
  padding-left: 0px;
  padding-top: 5px;
  font-size: 0.8rem;
  color: #bbbbbb;
}

@media only screen and (max-width: 600px) {
  .cconsole_head {

    font-size: 24px;

  }
  .allServices {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 0px;
  }
}
.prod_card{
    cursor: pointer;
    width: 100%;
    height: 410px;
    transition: all ease-in-out 0.1s;
    padding-bottom: 30px;
    margin-bottom: 20px;
}
.prod_card:hover{
    /* box-shadow: 3px 3px 1px 1px #2c69d1; */
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 11%);
}
.prod_card_img{
    height: 70%;
    margin-bottom: 10px;
}
.prod_card_img img{
    height: 100%;
    width: 100%;
    object-fit: fill;
}
.prod_details{
    height: 30%;
    padding: 10px;
}
.prod_name{
    color: #878787;
    font-size: 21px;
    word-wrap: break-word;
}
.prod_price{
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.815);
}
.prod_sku{
    font-size: 19px;
    color:black;
}
.main_prod_wrap{
    background-color: white;
    min-height: 86vh;
    border-radius: 4px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-top: 30px;
}
.pcb_market_header{
    background-color: #000;
    /* background-image: linear-gradient(315deg, #0abcf9 0%, #2c69d1 74%); */
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    width: 100vw;
    z-index: 10;
}
.pcb_market_search{
    display: flex;
    width: 100%;
    justify-content: center;
}
.pcb_market_search>input{
    width: 85%;
    max-width: 850px;
    padding: 10px;
    font-size: 1.3em; 
    padding-left: 20px;
    border:0px;
    outline: none;
    border-radius: 4px 4px 4px 4px;
   
}
.pcb_market_search>input:focus{
    box-shadow: 0px 0px 0px 4px #ffffff96;
}
.srch_icn{
    color: white;
    background-color: #0abcf9;
    margin-left: -50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
    font-weight: 700;
}
.srch_icn svg{
    font-size: 2em;
}
.srch_icn:hover{
    background-color: #0abdf9d0;
}
.right_pcbHeader_content{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    color: white;
    padding-right: 20px;
}
.right_pcbHeader_content>div:nth-child(3){
    display: flex;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.right_pcbHeader_content>div:nth-child(2){
    color: white;
}
.right_pcbHeader_content>div:nth-child(4){
    display: flex;
    cursor: pointer;
}
#vertical_divider{
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255, 0.685);
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
}
.add_prod_head{
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 35px;
    font-weight: 700;
    color: #2c69d1;
}
#setting_inp{
    border-radius: 10px;
    margin-left: 10px;
    outline: none;
    padding-left: 13px;
    border: 0px;
    background-color: rgba(0, 0, 0, 0.055);
}
.main_adminPcb_body{
    overflow-y: auto;
    height: calc(100vh - 80px);
background-color: #f4f4f4;
}
#macc{
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
#mmacc{
    padding-left: 14px;
cursor: pointer;
border-radius: 3px;
    padding-right: 4px;
    border: 1px solid transparent;

}
#mmacc:hover{
    border: 1px solid white;
}
#mmacc:focus{
    border: 1px solid white;
}
.acct_sett{
    padding: 10px;
    padding-left: 20px;
    padding-right: 30px;
}
.acct_sett>div:nth-child(1){
    font-size: 1.3em;
    font-weight: 600;
    padding-bottom: 5px;

}
.liprop{
    color: black !important;
    font-size: 1.1em;
}
.liprop a{
    text-decoration: none;
    color: black !important;
    transition: none !important;
   
}
.liprop a:hover{
    color: hsl(217,65%,49%) !important;
    text-decoration: underline;     
    -webkit-text-decoration-color: hsl(217,65%,49%) !important;     
            text-decoration-color: hsl(217,65%,49%) !important;;  
}
.liprop_logout{
    cursor: pointer;
    font-size: 1.1em;
}
.liprop_logout:hover{
    color: hsl(217,65%,49%) !important;
    text-decoration: underline;     
    -webkit-text-decoration-color: hsl(217,65%,49%) !important;     
            text-decoration-color: hsl(217,65%,49%) !important;; 
}
.srch_icn_mod{
    color: white;
}
.header__cart a {
    color: white !important
}
@media screen and (max-width:1085px){
    .pcb_market_search{
        display: none;
        justify-content: flex-start;
    }
    .icnSearch_btn{
        display: inline-block;
    }
    .logo_imgasd img{
        width: 120px;
    }
    .right_pcbHeader_content>div:nth-child(3){
        display: none;
    }
    .right_pcbHeader_content>div:nth-child(4){
        display: none;
    }
    .right_pcbHeader_content>div:nth-child(5){
        display: none;
    }
    .right_pcbHeader_content{
        padding-right: 0px !important;
    }
    .right_pcbHeader_content.invisible{
        display: none;
    }
    .pcb_market_search.visible{
        display: flex;
    }
    .logo_imgasd.invisibe{
        display: none !important;
    }
}
@media screen and (min-width:1085px){
    .icnSearch_btn{
        display: none;
    }
    #ardsc{
        display: none;
    }
}

.icnSearch_btn button{
    color: white !important;  
}

.icnSearch_btn button svg{
    font-size: 30px;
}
#all_ordrs{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 17px;
}
#s2132d{
    border: 1px dotted rgba(0, 0, 0, 0.288);
    padding: 8px;
    border-radius: 5px;
}

.approve_inputs form {
 display: flex;
 flex-direction: column;
 margin: 10px 0px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    margin: 10px 0px;
}
.prodDescription{
    white-space: pre-wrap;
}
.viewProdHead{
    font-size: 1.786em;
    line-height: 1.28em;
    margin-bottom: .48em;
}
@media screen and (min-width:700px) {
    div.description_area{
        padding-left: 80px;
    }  
}
div.description_area{
    
    margin-top: 40px;
    margin-bottom: 40px;
}
.viewProdAvailable{
    font-size: 16px;
    margin-bottom: 15px;
    color: #768b9e;
}
.skuName{
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
}
.prodDescription{
    padding-left: 20px;
    padding-top: 15px;
}
.curPrice{
    font-size: 30px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 14px;
}
.addToCartBtn{
    margin-left: 30px;
    cursor: pointer;
    height: 53px;
    border-radius: 13px;
    outline: none;
    border: 0px;
    font-size: 1.3em;
    padding: 10px;
    width: 200px;
    background-color: #2c69d1;
    color: white;
    font-weight: 600;
    
}
.addToCartBtn:hover{
    background-color: #2c69d1d7;
}
.skze{
    font-size: 14px;
    margin-top: 10px;
}
.skze1{
    margin-top: 22px;
}
.header_feature{
    font-size: 25px !important;
    font-weight: 700 !important;
}
#specs{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
#specs td, #specs th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  #specs tr:hover {background-color: #ddd;}
.pcb-cart {
    padding-bottom: 50px;
} 
.cart_head {
    text-align: center;
}
.cart_table_content {
    border: 1px solid rgb(168, 162, 162);
}
.cart_table {
    width: 100%;
    text-align: center;
    border-spacing: 0px;
    
}
.cart_table thead {
    border-bottom: 1.2px solid rgb(214, 206, 206);
}
.cart_table tr, td, th {
    padding: 10px 0px;
    border-bottom: 1px solid rgb(139, 133, 133);
}
td img {
    max-height: 50px;
}
.cart_pd {
    display: flex;
    align-items: center;
    justify-content: start;
}
.cart_item_delete {

}

button:focus {
    outline: none;
}

.cart_bottom {
    margin: 0px  30px 20px 0px;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.cart_total {
    font-weight: 800;
    text-align: right;
    padding-top: 8px;
    width: 100%;
    padding-right: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 15px;
}
.coupon input {
    border: none;
    border: 1px solid gray;
    padding: 8px 10px;
    border-radius: 20px 0px 0px 20px;
}
.proceed_cart{
  margin-bottom:10px;
}
.proceed_cart button {
    padding: 9px 25px;
    margin-left: 13px;
    border: none;
    background-color: #0ABCF9;
    color: white;
    border-radius: 7px;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
}

.proceed_cart button:hover {
    background-color: #0a91f9;
    transition: all .6s ease-in-out;
}


.add_new_ship {
    margin-top: 30px;
}

.add_new_ship {
    display: flex;
    flex-direction: column;
}
.single_address {
    border: 1px solid rgb(38, 162, 211);
    background-color: rgb(168, 225, 240);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
.shipRadio p {
    margin: 2px;
    font-size: 12px;
}
.shipRadio h6 {
    margin: 0px;
    font-size: 13px;
}

.add_new_ship * {
    margin: 0px;
}
.shipRadio {
    line-height: normal;
    padding: 10px 10px 10px 0px;
    text-transform: capitalize;
    
}
#singleAdrr{
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
}
#singleAdrr:hover{
    background-color: rgb(168, 225, 240);;
}

.shipping_inputs form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.shipping_inputs button {
    /* background-color: rgb(78, 78, 218);
    color: white; */
    margin-top: 20px;
    width: 200px;
    display: flex;
    align-self: center;
    margin-bottom: 60px;
}

.shipping_to{
    margin-top: 30px;
}
.shipping_to p {
 margin: 0px;
}
.order_status_modal {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(200, 185, 212);
}
.order_status_modal img {
    max-width: 120px;
}
.s_product {
    padding: 10px !important;
    border: 1px solid rgb(192, 155, 226);
    margin-bottom: 5px;
}
.order_status {
    font-size: 14px;
    font-weight: normal;
    color: rgb(230, 34, 34)
}
.p_details {
    margin-left: 20px;
    font-size: 10px;
}
.order_ship_time {
    font-size: 14px;
    margin-top: -10px;
}
.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content{
    display:flex;
    align-items: center;
}
#billBreakDown{
    border-collapse: collapse;
}

#billBreakDown tr>td:nth-child(2){
    padding-left: 25px;
}
#billBreakDown td{

    border-color: #00000021;
}
.main-body {
    margin: 3rem
}

/* Bom explorer */
.explorer-title{
    font-size: 2rem;
    font-weight: 600;
}

.quotation-title{
    font-size: 1.7rem;
    font-weight: 600;
}

.explorer-subtitle{
    font-size: 1.8rem;
    font-weight: 500;
}

.quotation-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
}

.explorer-muted-subtitle{
    font-size: 1.3rem;
    font-weight: 300;
    color: #929292
}

.quotation-muted-subtitle{
    font-size: 1rem;
    font-weight: 300;
    color: #929292
}

.flex-col-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Quotations page */
.flex{
    display: flex
}
.justify-between {
    justify-content: space-between;
}

.primary-btn {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-style: none;
    padding: 0.8rem 1rem;
    color: #F4F4F4;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 1rem;
    background-color: #0275FC;
}

.secondary-btn{
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-style: none;
    padding: 0.8rem 1rem;
    color: #0275FC;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 1rem;
    background-color: #F4F4F4;
}
.secondary-btn:hover{
    background-color: #ffffff;
}

#order-id-title {
    font-size: 1.1rem;
    color: #565656;
}
#order-id{
    font-weight: 600;
}

.subtitle-container {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.card-title {
    font-size: 1.3rem;
    font-weight: 600;
}

.explorer-card{
    width: 18rem; 
    padding: 3rem;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    background-color: #fff;
}
.explorer-card-hover:hover{
    box-shadow:0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); 
    cursor: pointer;
}

.modal-heading {
    font-size: 1.4rem;
    font-weight: 500;
}

.quote-container {
    margin-bottom: 3rem;
}

.quote-component-details {
    font-size: 1.4rem;
    display: flex;

}

.quotations-main{
    flex: 5 1
}

.summary-container {
    flex: 0 1;
    display: flex;
    justify-content: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.heading {
    font-family: Roboto;
    font-weight: 400;
    font-size: 32px;
    line-height: 16px;
}
.content_header {
    padding: 50px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%
}
.main_outer_container{
    width: 100vw;
    height:100vh;
    display:flex;
    flex-direction: column;
}
.inner_container{
    flex:1 1;
    overflow-y: auto;
    padding:10px 50px
}
.console_wrapper{
    background-color: #f4f4f4;
    min-height: 100vh;
}
#loader{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.sservice_finder{
    background: #ffffff;
    box-shadow: 0px 1px 3px 0px #00000033;
    height: 240px;
    /* max-width: 924px; */
    left: 0px;
    top: 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
}
.aallServices{
    background: #ffffff;
    box-shadow: 0px 1px 3px 0px #00000033;
    margin-top: 30px;
    margin-bottom: 30px;
    /* max-width: 924px; */
    left: 0px;
    top: 0px;
    border-radius: 4px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
  
}
.service_text{
    padding:10px;
    color: #6300eed0;
    text-transform: uppercase;
    border:1px solid white;
}
.service_text:hover{
    color: #6300eeec;
    border:1px solid #6300ee3f;
    cursor: pointer;
    background-color: #6300ee17;
    border-radius: 5px;
    
}
.dashboard_wrapper1{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}
.dashboard_wrapper{
    display: flex;
    width: 100vw;
    flex-direction: row;
    background-color: #E9E9E9;
    height: 100vh;
}
.sider_menu{
    width: 250px;
    background-color: black;
    height: 100vh;
    color: white;
    overflow-y: auto;
    text-align: center;
  
    align-content: center;
}
.sider_menu img{
    margin-top: 20px;
}
.right_dashboard_content{
    /* width: calc(100% - 250px); */
    width: 100%;
}
.header_wrapper{
    display: flex;
    padding: 10px;
   
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-right: 20px;
    background-color: white;  
}
.pop_over_item{
    cursor: pointer;
}
.pop_over_item:hover{
    background-color: #E9E9E9;
}
.stats_card{
    display: flex;
    min-height: 150px;
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    padding-left: 20px;
}
.stats_card>div:nth-child(2){
    padding-left: 20px;
}
.right_dashboard_content{
    height: 100vh;
    overflow-y: hidden;
}
.right_dashboard_content.admin{
    overflow-y: auto;
}
.paper_content_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.stats_wrapper{
    overflow-x: hidden;
  /* padding: 30px; */
}
.main_body{
    overflow-x: hidden;
}
.admin_main_body{
    background-color:#E9E9E9 ;
    min-height: 100vh;
}
.dialog_remark{
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.089);
    margin-bottom: 10px;
}
.dialog_remark div:nth-child(2){
    color: rgba(0, 0, 0, 0.767);
 
    padding-top: 5px;
}
.dialog_remark div:nth-child(1){
    font-size: 18px;
    font-weight: 500;
}
.sec{
    padding-bottom: 10px;
    color: black;
}
.date_div{
    font-size: 1.1em;
}
.stat_card{
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.123);
    border-radius: 2px;
    padding: 5px;
    padding-left: 15px;
    margin-bottom: 15px;
}
.stat_card>div:nth-child(1){
    font-size: 59px;
}
.stat_card>div:nth-child(2){
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;   
}
.main_body{
    width: 100%;
}
.sec_head{
    font-size: 1em;
    font-weight: 600;
    color: #787878;
    display: flex;
   
    text-transform: uppercase;
}
.sec>p{
    flex-wrap: nowrap;
    white-space: pre-wrap;
}
.chips{
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}
.document_item{
    margin-bottom: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.212);
    padding: 5px;
    padding-left: 10px;
    border-radius: 4px;
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width:600px) {
    .costing_expand>div:nth-child(1){
        display: none;
    }
    .stats_card{
        font-size: 14px;
        width: 89vw;
        padding-bottom: 10px;
        justify-content: center;
    }
    .stats_card>div:nth-child(1){
        display: none;
    }
    .stats_card>div:nth-child(2){
        text-align: center;
        margin-left: 0px;
        padding-left: 0px;
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 1.1em;  
        padding-right: 10px; 
    }
    button.consult_book_large{
        display: none !important;
    }
    button.consult_book_small{
        display: inline-block !important;
    }
    .stats_row{
        margin-right: 10px !important;
    }
    .stat_table_row{
        margin-right: 10px !important;
    }
}
.right_dashboard_content{
    overflow-x: hidden;
}
button.consult_book_small{
    display: none ;
}
button.consult_book_large{
    display: inline-block ;
}
.text_timeline{
    text-align: left;
    margin-bottom: 10px;
}
.details_btn{
    display: flex;
    height: 40px;
    align-items: center;
    align-content: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.main_body{
    overflow-x: hidden;
}
.stats_card{
    overflow-x: auto;
}
.dialog_remark{
    display: flex;
    align-items: flex-start;
    align-content: center;
    overflow-x: hidden;
    min-width: 200px;
}
.remark_abt{
    margin-left: 20px;
    width: 100%;
}
.remark_creator{
  
    width: 100%;
}
.author_name{
    font-size: 15px;
    font-weight: 700;
    padding-right: 14px;
}
.cmt_date{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.527);
    font-weight: 500;
}
.avatar_area{
    padding-top: 7px;
}
.remark_info{
    font-size: 15px;
}
._wrap{
    overflow-y: hidden;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.foot_table{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.t_header{
    text-transform: uppercase;
    padding-left: 10px;
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.747);
}
.right_dashboard_content{
    background-color:#f4f4f4;
}
.header_wrapper{
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.075);
}
.project_card{
    border-radius: 10px;
   
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: .625rem;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: white;
    transition: all ease-in-out 0.1s;
}
.project_card:hover{
    box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);
}
.project_card div{
    margin-bottom: 3px;
}
.project_name_card{
    font-size: 1.7em;
    font-weight: 600;
    padding-top: 15px;
    text-transform: uppercase;
}
.card_view_btn{
    margin-top: 20px;
    padding-bottom: 20px;
}
.time_wrap{
    min-height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}
.stat_wrapper{
    padding-top: 20px;
}
.card_view_btn a{
    text-decoration: none;
}
#fon_head{
    color: rgba(0, 0, 0, 0.753);
    font-size: 1.6em;
    padding-bottom: 20px;
}
.btn_opts_del{
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.documentHoler{
    height: 350px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.178);
    /* padding: 10px; */
    border-radius: 5px;
}
.documentItem{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    width: 90%;
    overflow-x: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: flex;
    border-right: 1px solid rgba(0, 0, 0, 0.116);
}
.document_Item{
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
}
#noDocAval{
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 15px;
}
.main_body{
    height: calc(100vh - 79px);
    /* background-color: #f4f4f4; */
    background-color: #fff;
}
.project_name_top{
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
}
.box_type{
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 14px;
}
.stats_card_right{
    font-size: 1.1rem;  
    color:#787878;
   
    width: 100%;
    padding-right: 20px;
}
.box__type{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 14px;
    width: 100%;
}
.box__type span:nth-child(1){
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
}
.detaails_btn{
    font-size: 17px;
    display: flex;
    font-weight: 600;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.685);
}
.detaails_btn:hover{
    color:#7B61FF;
}
.collapse_content{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
   
}
.collapse_content.visible{
    max-height: 400px;
   
}
.expand_btn.visible{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.expand_btn{
    margin-top: -1.4px;
    transition: all ease-in-out 0.1s;
}
.collapse_content{
    background: white;
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    border-radius: 10px;
    padding-left: 20px;
}
.costing_expand{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    align-content: center;
    align-items: center;
}
.hasscnd{
    margin-left: 42px;
    width: 100%;
}
.donKnow{
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}
.view_proj_wrapper{
    display: flex;
}
.right_proj_view{
    width: 50%;
}
.left_proj_view{
    width:50%;
}
.divider{
    background: rgba(0, 0, 0, 0.11);
    height: 1px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 93%;
}
.cost_el{
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    font-size: 1.1em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.678);
}
div.MuiTimelineOppositeContent-root{
    flex:0.2 1;
    
}
.form_el_cont{
    text-align: center;
    display: flex;
}
.proj_description_head{
    font-size: 23px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.932);
    margin-top: 42px;
    padding-bottom: 20px;
   
}
.proj_description{
    white-space: pre-wrap;
}
.bill_cmp{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
}
.bill_cmp.large{
    font-size: 22px;
}
.bill_cmp.large .bill_type{
    font-size: 22px;
}
.bill_cmp.large .bill_amount{
    font-size: 22px;
}
.bill_type{
    font-weight: 600;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.726);
}
.bill_amount{
    font-weight: 600;
    font-size: 17px;
    color: rgb(0, 0, 0);
}
.break_down_head{
    padding-left: 20px;
    margin-top: 18px;
    font-size: 17px;
    font-weight: 600;
    color: black;
    margin-bottom: 5px;
}
.collaborator_wrapper{
    display: flex;
    align-items: center;
    align-content: center;
}
.list_edit_item{
    display: flex;
    justify-content: space-between;
    padding-right: 7px;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.068);
    padding-bottom: 7px;
    padding-top: 5px;
}
.xcvr{
    margin-left: 7px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.815);
    font-size: 16px;
}
.collaborator_wrapper{
    display: flex;
}
.invite_text{
    font-size: 1.2em;
    padding-top: 20px;
    padding-bottom: 20px;
}
.remark_section_contain{
    max-height: 300px;
    overflow-y: auto;
}
.slector_cmts{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;   
}
.attach_icn{
    margin-right: 3px;
    padding-top: 13px;
    margin-left: -10px;
    display: flex;
}
div.downloadCmt{
    font-size: 1em !important;
    color: rgba(0, 0, 0, 0.747);
    font-weight: 600 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shipping_address {
    
}
.shipping_address h6 {
    margin: 0;
    padding: 0;
    color: rgba(41, 36, 36, 0.981);
}
.shipping_address p {
    margin: 0;
    padding: 0;
}
.product_d,
.user_d,
.shipping_add {
    margin: 10px 0px;
}

.selectStatus {
    display: flex;
    flex-direction: column;
}

.shipping_address h5 {
    color: black;
}

.active_status {
 background-color: rgb(136, 136, 221);
 color: white;
 border-radius: 4px;
}

.status {
    background: #fff;
    color: rgba(49, 42, 42, 0.884);
}
.status_btn {
    width: 200px;
    margin-top: -5px;
    border: none;
    border: 1px solid blueviolet;
    padding: 5px 20px; 
    background: blueviolet;
    color: white;
    border-radius: 3px;
}
.status_list {
    width: 200px;
    list-style-type: none;
    padding: 0px;
}
.status_list li {
    padding: 10px;
    text-align: center;
}
.status_message {
    margin-top: 10px;
}
.selectStatus h6 {
    color: rgba(41, 36, 36, 0.981);
}
.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
}
.textfields input{
    
    border:none
 }
 .textfields input:focus{
     outline: none;
 
 }
 
.top {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.details {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 1.8rem;
}

.text {
    width: 100%;
    margin-bottom: 2rem !important;
}

.sub-title {
    color: gray;
    margin-bottom: 4rem;
    margin-top: -0.5rem;
}

.button {
    background-color: #0275fc !important;
    width: 50%;
    color: white !important;
}

@media screen and (max-width:768px) {
    .text {
        width: 80%;

    }
}

.supplier_form{
    text-align: center;
}
.main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem;

}


.textfields input{
   border:none;
}
.textfields input:focus{
    outline: none;

}
.top{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
.details{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.title{
    font-size: 1.8rem;
}
.textCustomer {
   width: 20rem;
   margin: 1rem !important;
   margin-bottom: 2rem !important;
}
.sub-title{
    color: gray;
    margin-bottom: 1.2rem;
    margin-top: -0.5rem;
}
.textfields{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
        
}
.phoneInput{
    width: 20rem;
    margin: 1rem !important;
    margin-bottom: 2rem !important;
    padding-left: 0.8rem !important;
}

.buttonCustomer{
    background-color: #fca702 !important;
    width: 40%;
    color:white !important;
}
@media screen and (max-width:768px) {
    .textCustomer{
        width: 70%;
        font-size: 0.8rem;

    }
    .main{
        margin: 1rem;
    }
    .top{
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
}
.main-d{
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-d{
    background-color: black;
    position: relative;

    width: 50%;
    height: 100vh;
    margin: 0%;
    padding: 0;
}
.img-d{
    position: absolute;
    top:50%;
    left:50%;
    right: 50%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 13rem;
    height: 13rem;
}
.right-d{
    width:50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.customer-d,.supplier-d{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 70%;
    height: 12rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 10px;

}
.customer-d{
    background-color: #fca702;

}

.supplier-d{
    background-color: #0275fc;
}
.btn{
    background-color: black !important;
    color: white!important;
    margin-top: 1rem !important;
}
.title{
    font-weight: 800;
    color: black;
}
@media screen and (max-width:768px) {
    .left-d{
        display: none;
    }
    .right-d{
        width: 100%;
    }
}


/* Bom explorer */
.explorer-title{
    font-size: 2rem;
    font-weight: 600;
}

.quotation-title{
    font-size: 1.7rem;
    font-weight: 600;
}

.explorer-subtitle{
    font-size: 1.8rem;
    font-weight: 500;
}

.quotation-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
}

.explorer-muted-subtitle{
    font-size: 1.3rem;
    font-weight: 300;
    color: #929292
}

.quotation-muted-subtitle{
    font-size: 1rem;
    font-weight: 300;
    color: #929292
}

.flex-col-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Quotations page */
.flex{
    display: flex
}
.justify-between {
    justify-content: space-between;
}

.primary-btn {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-style: none;
    padding: 0.8rem 1rem;
    color: #F4F4F4;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 1rem;
    background-color: #0275FC;
}

.secondary-btn{
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-style: none;
    padding: 0.8rem 1rem;
    color: #0275FC;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 1rem;
    background-color: #F4F4F4;
}
.secondary-btn:hover{
    background-color: #ffffff;
}

#order-id-title {
    font-size: 1.1rem;
    color: #565656;
}
#order-id{
    font-weight: 600;
}

.subtitle-container {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.card-title {
    font-size: 1.3rem;
    font-weight: 600;
}

.explorer-card{
    width: 18rem; 
    padding: 3rem;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    background-color: #fff;
}
.explorer-card-hover:hover{
    box-shadow:0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); 
    cursor: pointer;
}

.modal-heading {
    font-size: 1.4rem;
    font-weight: 500;
}

.quote-container {
    margin-bottom: 3rem;
}

.quote-component-details {
    font-size: 1.4rem;
    display: flex;

}

.quotations-main{
    flex: 5 1
}

.summary-container {
    flex: 3 1;
    display: flex;
    justify-content: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.l_body form{
    display: flex;
    flex-direction: column;
    /* max-width: 8px; */
    width: 100%;
    max-width: 700px;
    justify-content: center;
}

.main_new_login{
    display: flex;
    justify-content: "center";
    align-items: "center";
}

.main_new_login .left_black{ 
    background-color: black;
    width: 50%;
}
.loginHeader{
  height: 5vh;
}

.l_body{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 60px;
}
.l_body h3{
    font-size: 2em;
    margin-bottom: 60px;
}
.loginWrapper{
    display: flex;
    overflow-x: hidden;
}
.left_content{
    width: 65%;
    height: 100vh;
    overflow-y: auto;
}
.l_header{
    padding: 20px;
}
.right_content{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 35%;
    height: 100vh;
    background-color: #2E2E2E;
    overflow:hidden;
}
@media screen and (max-width:1144px) {
    .left_content{
        width: 105%;
        height: 100vh;
        overflow-y: auto;
        padding-right: 30px;
        padding-left: 30px;
    }
    .right_content{
        display: none;
    }
}
.or_opt{
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid rgba(0, 0, 0, 0.342); 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    padding-top: 19px;
}
.or_opt span { 
    background:#fff; 
    padding:0 10px; 
}
.sign_up{
    padding-top: 30px;
    font-size: 20px;
}
.signUp_head_login{
    font-size: 1.9em;
    margin-bottom: 40px;
    margin-top: 30px;
    font-weight: 600;
}
.leftt_content_login{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 95vh;
    margin-top: -80px;
}
.rightt_content_login{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;  
    height: 95vh;
}
.leftt_content_login_supplier1{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 120vh;
    margin-top: -80px;
    background-color: black;
    width: 50%;
    position: relative;
    margin-left: -2rem;
}





.signUpheaderLogin{
    position: absolute;
    top:50%;
    left:50%;
    right: 50%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    
}
.signUpheaderLogin img{
    width: 13rem;
    height: 13rem;
}

.content_wrap_supplier{
    display: flex;
    justify-content: center;
    align-items: center;
}
.main_content_login{
    width:50%;
    /* margin: 1rem; */
    padding: 1rem; 
}
@media screen and (max-width:997px)  {
    .leftt_content_login, .leftt_content_login_supplier1{
        display: none;
    }
    .rightt_content_login{
        display: none;
    }
    .main_content_login{
        width:100%;
        /* margin: 1rem; */
        padding: 1rem; 
    }
  }
