
.add_new_ship {
    display: flex;
    flex-direction: column;
}
.single_address {
    border: 1px solid rgb(38, 162, 211);
    background-color: rgb(168, 225, 240);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
.shipRadio p {
    margin: 2px;
    font-size: 12px;
}
.shipRadio h6 {
    margin: 0px;
    font-size: 13px;
}

.add_new_ship * {
    margin: 0px;
}
.shipRadio {
    line-height: normal;
    padding: 10px 10px 10px 0px;
    text-transform: capitalize;
    
}
#singleAdrr{
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
}
#singleAdrr:hover{
    background-color: rgb(168, 225, 240);;
}