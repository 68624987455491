.dashboard_wrapper1{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}
.dashboard_wrapper{
    display: flex;
    width: 100vw;
    flex-direction: row;
    background-color: #E9E9E9;
    height: 100vh;
}
.sider_menu{
    width: 250px;
    background-color: black;
    height: 100vh;
    color: white;
    overflow-y: auto;
    text-align: center;
  
    align-content: center;
}
.sider_menu img{
    margin-top: 20px;
}
.right_dashboard_content{
    /* width: calc(100% - 250px); */
    width: 100%;
}
.header_wrapper{
    display: flex;
    padding: 10px;
   
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-right: 20px;
    background-color: white;  
}
.pop_over_item{
    cursor: pointer;
}
.pop_over_item:hover{
    background-color: #E9E9E9;
}
.stats_card{
    display: flex;
    min-height: 150px;
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    padding-left: 20px;
}
.stats_card>div:nth-child(2){
    padding-left: 20px;
}
.right_dashboard_content{
    height: 100vh;
    overflow-y: hidden;
}
.right_dashboard_content.admin{
    overflow-y: auto;
}
.paper_content_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.stats_wrapper{
    overflow-x: hidden;
  /* padding: 30px; */
}
.main_body{
    overflow-x: hidden;
}
.admin_main_body{
    background-color:#E9E9E9 ;
    min-height: 100vh;
}
.dialog_remark{
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.089);
    margin-bottom: 10px;
}
.dialog_remark div:nth-child(2){
    color: rgba(0, 0, 0, 0.767);
 
    padding-top: 5px;
}
.dialog_remark div:nth-child(1){
    font-size: 18px;
    font-weight: 500;
}
.sec{
    padding-bottom: 10px;
    color: black;
}
.date_div{
    font-size: 1.1em;
}
.stat_card{
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.123);
    border-radius: 2px;
    padding: 5px;
    padding-left: 15px;
    margin-bottom: 15px;
}
.stat_card>div:nth-child(1){
    font-size: 59px;
}
.stat_card>div:nth-child(2){
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;   
}
.main_body{
    width: 100%;
}
.sec_head{
    font-size: 1em;
    font-weight: 600;
    color: #787878;
    display: flex;
   
    text-transform: uppercase;
}
.sec>p{
    flex-wrap: nowrap;
    white-space: pre-wrap;
}
.chips{
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}
.document_item{
    margin-bottom: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.212);
    padding: 5px;
    padding-left: 10px;
    border-radius: 4px;
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width:600px) {
    .costing_expand>div:nth-child(1){
        display: none;
    }
    .stats_card{
        font-size: 14px;
        width: 89vw;
        padding-bottom: 10px;
        justify-content: center;
    }
    .stats_card>div:nth-child(1){
        display: none;
    }
    .stats_card>div:nth-child(2){
        text-align: center;
        margin-left: 0px;
        padding-left: 0px;
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 1.1em;  
        padding-right: 10px; 
    }
    button.consult_book_large{
        display: none !important;
    }
    button.consult_book_small{
        display: inline-block !important;
    }
    .stats_row{
        margin-right: 10px !important;
    }
    .stat_table_row{
        margin-right: 10px !important;
    }
}
.right_dashboard_content{
    overflow-x: hidden;
}
button.consult_book_small{
    display: none ;
}
button.consult_book_large{
    display: inline-block ;
}
.text_timeline{
    text-align: left;
    margin-bottom: 10px;
}
.details_btn{
    display: flex;
    height: 40px;
    align-items: center;
    align-content: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.main_body{
    overflow-x: hidden;
}
.stats_card{
    overflow-x: auto;
}
.dialog_remark{
    display: flex;
    align-items: flex-start;
    align-content: center;
    overflow-x: hidden;
    min-width: 200px;
}
.remark_abt{
    margin-left: 20px;
    width: 100%;
}
.remark_creator{
  
    width: 100%;
}
.author_name{
    font-size: 15px;
    font-weight: 700;
    padding-right: 14px;
}
.cmt_date{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.527);
    font-weight: 500;
}
.avatar_area{
    padding-top: 7px;
}
.remark_info{
    font-size: 15px;
}
._wrap{
    overflow-y: hidden;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.foot_table{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.t_header{
    text-transform: uppercase;
    padding-left: 10px;
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.747);
}
.right_dashboard_content{
    background-color:#f4f4f4;
}
.header_wrapper{
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.075);
}
.project_card{
    border-radius: 10px;
   
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: .625rem;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: white;
    transition: all ease-in-out 0.1s;
}
.project_card:hover{
    box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);
}
.project_card div{
    margin-bottom: 3px;
}
.project_name_card{
    font-size: 1.7em;
    font-weight: 600;
    padding-top: 15px;
    text-transform: uppercase;
}
.card_view_btn{
    margin-top: 20px;
    padding-bottom: 20px;
}
.time_wrap{
    min-height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}
.stat_wrapper{
    padding-top: 20px;
}
.card_view_btn a{
    text-decoration: none;
}
#fon_head{
    color: rgba(0, 0, 0, 0.753);
    font-size: 1.6em;
    padding-bottom: 20px;
}
.btn_opts_del{
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.documentHoler{
    height: 350px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.178);
    /* padding: 10px; */
    border-radius: 5px;
}
.documentItem{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    width: 90%;
    overflow-x: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: flex;
    border-right: 1px solid rgba(0, 0, 0, 0.116);
}
.document_Item{
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
}
#noDocAval{
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 15px;
}
.main_body{
    height: calc(100vh - 79px);
    /* background-color: #f4f4f4; */
    background-color: #fff;
}
.project_name_top{
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
}
.box_type{
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 14px;
}
.stats_card_right{
    font-size: 1.1rem;  
    color:#787878;
   
    width: 100%;
    padding-right: 20px;
}
.box__type{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 14px;
    width: 100%;
}
.box__type span:nth-child(1){
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
}
.detaails_btn{
    font-size: 17px;
    display: flex;
    font-weight: 600;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.685);
}
.detaails_btn:hover{
    color:#7B61FF;
}
.collapse_content{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
   
}
.collapse_content.visible{
    max-height: 400px;
   
}
.expand_btn.visible{
    transform: rotate(180deg);
}
.expand_btn{
    margin-top: -1.4px;
    transition: all ease-in-out 0.1s;
}
.collapse_content{
    background: white;
    box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    border-radius: 10px;
    padding-left: 20px;
}
.costing_expand{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    align-content: center;
    align-items: center;
}
.hasscnd{
    margin-left: 42px;
    width: 100%;
}
.donKnow{
    color:#7B61FF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}
.view_proj_wrapper{
    display: flex;
}
.right_proj_view{
    width: 50%;
}
.left_proj_view{
    width:50%;
}
.divider{
    background: rgba(0, 0, 0, 0.11);
    height: 1px;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 93%;
}
.cost_el{
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    font-size: 1.1em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.678);
}
div.MuiTimelineOppositeContent-root{
    flex:0.2;
    
}
.form_el_cont{
    text-align: center;
    display: flex;
}
.proj_description_head{
    font-size: 23px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.932);
    margin-top: 42px;
    padding-bottom: 20px;
   
}
.proj_description{
    white-space: pre-wrap;
}
.bill_cmp{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
}
.bill_cmp.large{
    font-size: 22px;
}
.bill_cmp.large .bill_type{
    font-size: 22px;
}
.bill_cmp.large .bill_amount{
    font-size: 22px;
}
.bill_type{
    font-weight: 600;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.726);
}
.bill_amount{
    font-weight: 600;
    font-size: 17px;
    color: rgb(0, 0, 0);
}
.break_down_head{
    padding-left: 20px;
    margin-top: 18px;
    font-size: 17px;
    font-weight: 600;
    color: black;
    margin-bottom: 5px;
}
.collaborator_wrapper{
    display: flex;
    align-items: center;
    align-content: center;
}
.list_edit_item{
    display: flex;
    justify-content: space-between;
    padding-right: 7px;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.068);
    padding-bottom: 7px;
    padding-top: 5px;
}
.xcvr{
    margin-left: 7px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.815);
    font-size: 16px;
}
.collaborator_wrapper{
    display: flex;
}
.invite_text{
    font-size: 1.2em;
    padding-top: 20px;
    padding-bottom: 20px;
}
.remark_section_contain{
    max-height: 300px;
    overflow-y: auto;
}
.slector_cmts{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;   
}
.attach_icn{
    margin-right: 3px;
    padding-top: 13px;
    margin-left: -10px;
    display: flex;
}
div.downloadCmt{
    font-size: 1em !important;
    color: rgba(0, 0, 0, 0.747);
    font-weight: 600 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}