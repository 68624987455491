.nothing_found{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 84vh;
}
.console_head{
    font-size: 1.8em;
    text-align: left;
    padding-left: 60px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.829);
}
.no_proj_text{
    font-size: 1.6em;
    color: rgba(0, 0, 0, 0.699);
}
.no_proj_text img{
    padding-left: 4px;
}
.form_label{
    margin-bottom: 5px;
    margin-top: 10px;
}
.billing_container{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
}
.billing_container h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 23px;
    
}
.actions_btns{
    margin-top: 20px;
    margin-right: 10px;
}
.billing_divider{
    margin-top: 25px;
    border-top: 2px dotted #3f51b542;
}
.back_btn{
    position: absolute;
    top: 12px;
}
.bill_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
}
.txt_wrap{
    text-align: left;
    overflow-x: hidden;
    padding-top:20px;
    padding-bottom: 20px;
    text-align: center;
   
}
.project_card{
    overflow-x: hidden;
    text-align: center;
    height: 550px;
}
.proj_card_description{
    width: 100%;
    padding-left: 20px;    
    text-align: left;
}
.proj_card_description>div:nth-child(1){
    font-size: 1.1em;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.822);
}
.proj_card_description>div:nth-child(2){
    white-space: pre-wrap;
    text-align: left;
    margin-right: 24px;
}
.txt_wrap{
    width: 100%;
    overflow-y:auto ;
}
/* width */
.txt_wrap::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
.txt_wrap ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
.txt_wrap::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
.txt_wrap::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
.xysad{
    padding-bottom: 25px;
    margin-left: 5px;
    font-size: 1.5em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.884);
}