.console_wrapper{
    background-color: #f4f4f4;
    min-height: 100vh;
}
#loader{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.sservice_finder{
    background: #ffffff;
    box-shadow: 0px 1px 3px 0px #00000033;
    height: 240px;
    /* max-width: 924px; */
    left: 0px;
    top: 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
}
.aallServices{
    background: #ffffff;
    box-shadow: 0px 1px 3px 0px #00000033;
    margin-top: 30px;
    margin-bottom: 30px;
    /* max-width: 924px; */
    left: 0px;
    top: 0px;
    border-radius: 4px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
  
}
.service_text{
    padding:10px;
    color: #6300eed0;
    text-transform: uppercase;
    border:1px solid white;
}
.service_text:hover{
    color: #6300eeec;
    border:1px solid #6300ee3f;
    cursor: pointer;
    background-color: #6300ee17;
    border-radius: 5px;
    
}