.pcb_market_header{
    background-color: #000;
    /* background-image: linear-gradient(315deg, #0abcf9 0%, #2c69d1 74%); */
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    width: 100vw;
    z-index: 10;
}
.pcb_market_search{
    display: flex;
    width: 100%;
    justify-content: center;
}
.pcb_market_search>input{
    width: 85%;
    max-width: 850px;
    padding: 10px;
    font-size: 1.3em; 
    padding-left: 20px;
    border:0px;
    outline: none;
    border-radius: 4px 4px 4px 4px;
   
}
.pcb_market_search>input:focus{
    box-shadow: 0px 0px 0px 4px #ffffff96;
}
.srch_icn{
    color: white;
    background-color: #0abcf9;
    margin-left: -50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
    font-weight: 700;
}
.srch_icn svg{
    font-size: 2em;
}
.srch_icn:hover{
    background-color: #0abdf9d0;
}
.right_pcbHeader_content{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    color: white;
    padding-right: 20px;
}
.right_pcbHeader_content>div:nth-child(3){
    display: flex;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.right_pcbHeader_content>div:nth-child(2){
    color: white;
}
.right_pcbHeader_content>div:nth-child(4){
    display: flex;
    cursor: pointer;
}
#vertical_divider{
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255, 0.685);
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
}
.add_prod_head{
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 35px;
    font-weight: 700;
    color: #2c69d1;
}
#setting_inp{
    border-radius: 10px;
    margin-left: 10px;
    outline: none;
    padding-left: 13px;
    border: 0px;
    background-color: rgba(0, 0, 0, 0.055);
}
.main_adminPcb_body{
    overflow-y: auto;
    height: calc(100vh - 80px);
background-color: #f4f4f4;
}
#macc{
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
#mmacc{
    padding-left: 14px;
cursor: pointer;
border-radius: 3px;
    padding-right: 4px;
    border: 1px solid transparent;

}
#mmacc:hover{
    border: 1px solid white;
}
#mmacc:focus{
    border: 1px solid white;
}
.acct_sett{
    padding: 10px;
    padding-left: 20px;
    padding-right: 30px;
}
.acct_sett>div:nth-child(1){
    font-size: 1.3em;
    font-weight: 600;
    padding-bottom: 5px;

}
.liprop{
    color: black !important;
    font-size: 1.1em;
}
.liprop a{
    text-decoration: none;
    color: black !important;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
   
}
.liprop a:hover{
    color: hsl(217,65%,49%) !important;
    text-decoration: underline;     
    text-decoration-color: hsl(217,65%,49%) !important;;  
}
.liprop_logout{
    cursor: pointer;
    font-size: 1.1em;
}
.liprop_logout:hover{
    color: hsl(217,65%,49%) !important;
    text-decoration: underline;     
    text-decoration-color: hsl(217,65%,49%) !important;; 
}
.srch_icn_mod{
    color: white;
}
.header__cart a {
    color: white !important
}
@media screen and (max-width:1085px){
    .pcb_market_search{
        display: none;
        justify-content: flex-start;
    }
    .icnSearch_btn{
        display: inline-block;
    }
    .logo_imgasd img{
        width: 120px;
    }
    .right_pcbHeader_content>div:nth-child(3){
        display: none;
    }
    .right_pcbHeader_content>div:nth-child(4){
        display: none;
    }
    .right_pcbHeader_content>div:nth-child(5){
        display: none;
    }
    .right_pcbHeader_content{
        padding-right: 0px !important;
    }
    .right_pcbHeader_content.invisible{
        display: none;
    }
    .pcb_market_search.visible{
        display: flex;
    }
    .logo_imgasd.invisibe{
        display: none !important;
    }
}
@media screen and (min-width:1085px){
    .icnSearch_btn{
        display: none;
    }
    #ardsc{
        display: none;
    }
}

.icnSearch_btn button{
    color: white !important;  
}

.icnSearch_btn button svg{
    font-size: 30px;
}
#all_ordrs{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 17px;
}
#s2132d{
    border: 1px dotted rgba(0, 0, 0, 0.288);
    padding: 8px;
    border-radius: 5px;
}

.approve_inputs form {
 display: flex;
 flex-direction: column;
 margin: 10px 0px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    margin: 10px 0px;
}