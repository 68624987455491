

/* Bom explorer */
.explorer-title{
    font-size: 2rem;
    font-weight: 600;
}

.quotation-title{
    font-size: 1.7rem;
    font-weight: 600;
}

.explorer-subtitle{
    font-size: 1.8rem;
    font-weight: 500;
}

.quotation-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
}

.explorer-muted-subtitle{
    font-size: 1.3rem;
    font-weight: 300;
    color: #929292
}

.quotation-muted-subtitle{
    font-size: 1rem;
    font-weight: 300;
    color: #929292
}

.flex-col-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Quotations page */
.flex{
    display: flex
}
.justify-between {
    justify-content: space-between;
}

.primary-btn {
    display: block;
    width: fit-content;
    border-style: none;
    padding: 0.8rem 1rem;
    color: #F4F4F4;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 1rem;
    background-color: #0275FC;
}

.secondary-btn{
    display: block;
    width: fit-content;
    border-style: none;
    padding: 0.8rem 1rem;
    color: #0275FC;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 1rem;
    background-color: #F4F4F4;
}
.secondary-btn:hover{
    background-color: #ffffff;
}

#order-id-title {
    font-size: 1.1rem;
    color: #565656;
}
#order-id{
    font-weight: 600;
}

.subtitle-container {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.card-title {
    font-size: 1.3rem;
    font-weight: 600;
}

.explorer-card{
    width: 18rem; 
    padding: 3rem;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    background-color: #fff;
}
.explorer-card-hover:hover{
    box-shadow:0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); 
    cursor: pointer;
}

.modal-heading {
    font-size: 1.4rem;
    font-weight: 500;
}

.quote-container {
    margin-bottom: 3rem;
}

.quote-component-details {
    font-size: 1.4rem;
    display: flex;

}

.quotations-main{
    flex: 5
}

.summary-container {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    height: fit-content;
}