.cconsole_head {
  height: 88px;
  left: 41px;
  top: 130px;
  margin-top: 24px;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.service_finder {
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px #00000033;
  height: 240px;
  max-width: 924px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
}

.find_serve_text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.find_serve_description {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.bookConsultation {
  height: 306px;
  width: 100%;
  left: 0px;
  top: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 4px;
}

.tanl_head {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.tal_des {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.795);
}

.allServices {
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px #00000033;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 924px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 80px;
  padding-right: 20px;
}

.serve_sec_head {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  margin-top: 40px !important;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.section_type {
  padding-left: 11px !important;
}

.service_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  /* color: #6200ee; */
  color: #0275FC;
  margin-top: 30px !important;
}

.service_text_text {
  padding-left: 10px;
  padding-top: 5px;
  color: #0275FC;
  text-transform: capitalize;
  font-size: 18px;
}

.service_description {
  padding-left: 10px;
  font-size: 15px;
  color: #838383;
  text-transform: capitalize;
  font-weight: 400;
  padding-left: 10px;
  padding-top: 2px;
  margin-top: 0.4rem;
}

.service_text_desc {
  padding-left: 0px;
  padding-top: 5px;
  font-size: 0.8rem;
  color: #bbbbbb;
}

@media only screen and (max-width: 600px) {
  .cconsole_head {

    font-size: 24px;

  }
  .allServices {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 0px;
  }
}