.main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem;

}


.textfields input{
   border:none;
}
.textfields input:focus{
    outline: none;

}
.top{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
.details{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.title{
    font-size: 1.8rem;
}
.textCustomer {
   width: 20rem;
   margin: 1rem !important;
   margin-bottom: 2rem !important;
}
.sub-title{
    color: gray;
    margin-bottom: 1.2rem;
    margin-top: -0.5rem;
}
.textfields{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
        
}
.phoneInput{
    width: 20rem;
    margin: 1rem !important;
    margin-bottom: 2rem !important;
    padding-left: 0.8rem !important;
}

.buttonCustomer{
    background-color: #fca702 !important;
    width: 40%;
    color:white !important;
}
@media screen and (max-width:768px) {
    .textCustomer{
        width: 70%;
        font-size: 0.8rem;

    }
    .main{
        margin: 1rem;
    }
    .top{
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
}