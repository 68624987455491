
.shipping_inputs form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.shipping_inputs button {
    /* background-color: rgb(78, 78, 218);
    color: white; */
    margin-top: 20px;
    width: 200px;
    display: flex;
    align-self: center;
    margin-bottom: 60px;
}
